@import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@600&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Montserrat", serif;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.recharts-default-tooltip {
  background-color: rgb(16 24 39) !important;
  border-radius: 10px;
  @layer components {
    .btn-primary {
      @apply flex h-10 items-center justify-center rounded-md bg-primary-500 text-white px-4 py-2;
    }
    .btn-danger {
      @apply flex h-10 items-center justify-center rounded-md bg-red-500 text-white px-4 py-2 disabled:cursor-not-allowed;
    }
  }
}
